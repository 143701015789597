import React from 'react'
import styled from 'styled-components'
import { useStaticQuery, Link } from "gatsby"
import { AiOutlineMail } from "react-icons/ai"
import { FaFacebookF } from "react-icons/fa"

const Wrapper = styled.div`
  height: 90px;
  margin-top: -90px;
  border-top: 1px solid #999;
`

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  align-items: center;
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 100%;
  align-items: center;
`

const PrivacyLink = styled(props => <Link {...props} />)`
  text-decoration: none;
  color: #333;
  margin-right: 12px;
`

const ContactWrapper = styled.div`
  font-size: 22px;
  width: 40px;
}
`

const ContactLink = styled.a`
  align-self: center;
  margin-left: 8px;
  text-decoration: none;
  margin-bottom: 12px;
  color: #666;
`

const Footer = () => {
  const { file } = useStaticQuery(
    graphql`
      query {
        file(name: {eq: "contact"}) {
          childMarkdownRemark {
            frontmatter {
              email
              facebook
            }
          }
        }
      }
    `
  )

  const { email, facebook } = file.childMarkdownRemark.frontmatter

  return (
    <Wrapper>
      <Container>
        <InnerWrapper>
          <ContactWrapper>
            <ContactLink href={`mailto:${email}`}><AiOutlineMail color="#333" /></ContactLink>
          </ContactWrapper>
          <ContactWrapper>
            <ContactLink href={facebook}><FaFacebookF color="#333" /></ContactLink>
          </ContactWrapper>
        </InnerWrapper>
        <InnerWrapper>
          <PrivacyLink to='/privacy'>
            Privacy policy
          </PrivacyLink>
          <span>© {new Date().getFullYear()}, Bildnis</span>
        </InnerWrapper>
      </Container>
    </Wrapper>
  )
}

export default Footer
