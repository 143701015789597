
import React from "react"
import styled from "styled-components"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const LogoWrap = styled.div`
  margin-left: -30px;
`
const Logo = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-bildnis-groot.png" }) {
        childImageSharp {
          fixed(width: 236, height: 82) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <LogoWrap as={Link} to="/">
      <Img
        width={236}
        height={82}
        fixed={data.logo.childImageSharp.fixed}
      />
    </LogoWrap>
  )
}

export default Logo