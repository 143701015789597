/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { Fragment } from "react"
import styled from 'styled-components'
import Footer from "./footer"
import "./layout.css"
import Navbar from "./navbar/navbar"

const Container = styled.div`
  margin: 0 auto;
  max-width: 960px;
  padding: 0 20px 200px;
  min-height: 100vh;
`

const Layout = ({ children, active }) => {
  return (
    <Fragment>
      <Container>
        <Navbar active={active} />
        {children}
      </Container>
      <Footer />
    </Fragment>
  )
}

export default Layout
