import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const NavLink = styled(props => <Link {...props} />)`
  font-family: 'Clarendon';
  color: #999;
  font-size: 1rem;
  margin-right: 1.6rem;
  text-decoration: none;
  &:hover {
    color: #279896;
  }
  &.activeLink {
    border-bottom: 2px solid #279896;
    color: #279896;
  }
`

const NavLinkWrapper = styled.div`
  @media (max-width: 768px) {
    padding: 10px;
  }
`

const NavbarLinks = ({active}) => {
  return (
    <>
          <NavLinkWrapper>
            <NavLink to='/agenda' className={active === "agenda" ? "activeLink": ''}>
              Agenda
            </NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper>
            <NavLink to='/about' className={active === "about" ? "activeLink": ''}>
              Over Bildnis
            </NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper>
            <NavLink to='/member' className={active === "member" ? "activeLink": ''}>
              Lid worden
            </NavLink>
          </NavLinkWrapper>
          <NavLinkWrapper>
            <NavLink to='/contact' className={active === "contact" ? "activeLink": ''}>
              Contact
            </NavLink>
          </NavLinkWrapper>
    </>
  )
}

export default NavbarLinks